import React from 'react';
import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material';
import Slider from 'react-slick';
import { useQueryAsUser } from '../../../../clients/graphql/graphql.hooks';
import { GQL_GetWarnings } from '../../../../clients/graphql/user/user-info.query';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CurrentPosition,
  SlaveAutoPilotPortfolio
} from "../../../../clients/graphql/user-pilots/user-pilot-details.query";
import moment from "moment/moment";

export function WarningCarousel({ userKey , depositFailures}) {

  const { data, error, loading } = useQueryAsUser(GQL_GetWarnings, {
    userKey,
    variables: { userKey }
  });
  if (loading) {
    return <CircularProgress size={60} sx={{ margin: '30px' }} />;
  }

  if (error) {
    return <Alert severity="warning">INTERNAL ERROR: could not load user warning logs</Alert>;
  }
  const warningList = data?.userAutoPilotWarningsGet?.warningPrompts ?? [];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Box sx={{ margin: '0 auto', width: '100%' }}>
      <Slider {...settings}>
        {
          warningList.map((w, index) => (
              <Alert key={index} severity="error" icon={false} sx={{ margin: 0, width: '100%' }} >
                  <p   style={{ fontSize: '11px'  }}>{w?.title} </p >
                  <p style={{ fontSize: '9px'  }}>{w?.description}</p>
              </Alert>
          ))
        }
        {
          (depositFailures??[]).map((slaves, index) => (
              <Alert key={index+warningList.length} severity="error" icon={false} sx={{ margin: 0, width: '100%' }} >
                <p style={{ fontSize: '11px'  }}> Deposit failed in {slaves.masterPortfolio.userFullName} </p >
                <p style={{ fontSize: '9px'  }}>Amount ${slaves.failedTransferData?.amount??0.0} date {
                  moment(slaves.failedTransferData?.submittedAt??Date()).format('MM/DD/YYYY HH:mm:ss A')
                }</p>
              </Alert>
          ))
        }
      </Slider>
    </Box>
  );
}
