import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import {gql} from "@apollo/client";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import React from "react";
import {useNavigate} from "react-router-dom";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {ConnectedStatus} from "../../components/status/ConnectedStatus";
import {DisconnectedStatus} from "../../components/status/DisconnectedStatus";
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import BadgeIcon from '@mui/icons-material/Badge';
import DateRangeIcon from '@mui/icons-material/DateRange';
import KeyIcon from "@mui/icons-material/Key";

interface props{
    portfolioKey: number,
}

export default function PortfolioCrudPanel({portfolioKey} : props) {

    const goTo = useNavigate()

    const whoIsIt = gql`
    query whoisit(
        $portfolioKey: Int!){
            whoisit(input: { portfolioKey: $portfolioKey}) {
                portfolio {
                    user{
                        userKey
                        firstName
                        lastName
                    }
                    connectionStatus
                    brokerName
                    portfolioName
                    deletedAt
                }
                institution{
                    institutionKey
                }
            }
        }`;

    const {loading, error, data} = useQueryAsUser(whoIsIt, {variables: {portfolioKey: portfolioKey}});

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>;
    }

    const curatedData = data?.whoisit?.portfolio;
    const curatedInstitutionData = data?.whoisit?.institution;

    return (<>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2 }}>
            <Typography variant="h6" component='div' >
                Overview
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Person2OutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`User: ${curatedData?.user?.firstName} ${curatedData?.user?.lastName}`} />
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/users/${curatedData?.user?.userKey}`)}
                    >
                        #{curatedData?.user?.userKey}
                    </Button>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Broker: ${curatedData?.brokerName}`} />
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem sx={{fontSize: "16px"}}>
                    <ListItemIcon>
                        <OfflineBoltIcon />
                    </ListItemIcon>
                    Status:&nbsp;
                    {curatedData?.connectionStatus === 'CONNECTED' ? <ConnectedStatus /> : <DisconnectedStatus />}
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Portfolio Name: ${curatedData?.portfolioName}`} />
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <KeyIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Institution Key`}/>
                    <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    color='primary'
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => goTo(`/institution/${curatedInstitutionData?.institutionKey}`)}
                    >
                        # {curatedInstitutionData?.institutionKey}
                </Button>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem >
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Deleted at: ${curatedData?.deletedAt == null ? 'N/A' : curatedData?.deletedAt}`} />
                </ListItem>
            </List>
        </Box>
        </>
    )
}