import {gql} from "@apollo/client";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {
    Alert,
    Box,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import React from "react";
import PaidIcon from '@mui/icons-material/Paid';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import moment from "moment/moment";

interface props{
    portfolioKey: number,
}

export default function PortfolioDetailsPanel({portfolioKey} : props) {

    const whoIsIt = gql`
    query whoisit(
        $portfolioKey: Int!){
            whoisit(input: { portfolioKey: $portfolioKey}) {
                portfolio {
                  financialInfo{
                    buyingPower
                    lastHardCheckAt
                    totalValue
                  }
                }
                institution{
                  createdAt
                }
            }
        }`;

    const {loading, error, data} = useQueryAsUser(whoIsIt, {variables: {portfolioKey: portfolioKey}});

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>;
    }

    const curatedPortfolioData = data?.whoisit?.portfolio?.financialInfo;
    const curatedInstitutionData = data?.whoisit?.institution;

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2 }}>
                <Typography variant="h6" component='div' >
                    Portfolio Details
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <PaidIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Total Value: ${curatedPortfolioData?.totalValue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon>
                            <LocalAtmIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Buying Power: ${curatedPortfolioData?.buyingPower?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`} />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon>
                            <MobileFriendlyIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Last Hard Checked At: ${moment(curatedPortfolioData?.lastHardCheckAt).format('MM/DD/YYYY HH:mm')}`} />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon>
                            <ChildCareIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Created At: ${moment(curatedInstitutionData?.createdAt).format('MM/DD/YYYY HH:mm')}`} />
                    </ListItem>
                </List>
            </Box>
        </>
    )
}