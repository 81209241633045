import { useMutationCoordinate, useQueryAsUser } from '../../clients/graphql/graphql.hooks';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BalancingDecision, balancingDecisionsQuery, BalancingParameters } from '../../clients/graphql/user-pilots/balancing-decisions.query';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import DoneIcon from '@mui/icons-material/Done';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import { Button, Dialog, Divider, Skeleton, Tooltip, Typography } from '@mui/material';
import * as _ from 'lodash';
import { CurrentPosition } from '../../clients/graphql/user-pilots/user-pilot-details.query';
import { jediPortfolioPositionsGet, JediPortfolioPosition } from '../../clients/graphql/portfolio/portfolio-positions.query';
import {StockSymbolComponent} from "../../pages/users/StockSymbolComponent";
import { InfoOutlined} from '@mui/icons-material';
import { useState } from 'react';
import {gql} from "@apollo/client";
import React from 'react';
import ConfirmAlertDialog from '../dialogs/confirm-alert';
import { jediResolveOverAllocation } from '../../clients/graphql/user-pilots/autopilot-settings.mutation';
import { CONNECTION_STATUS, BROKER_NAME } from '../../shared/portfolio-types';

interface Props {
    userKey: number;
    autoPilotSettingsKey: number;
    positions: CurrentPosition[];
    portfolioDetails: {
        portfolioKey: number;
        brokerName: BROKER_NAME;
        connectionStatus: string;
    }
}

const parseKindToActionNeeded = (kind: string) => {
    switch (kind) {
        case 'INoopDecision':
            return false;
        case 'IExecuteOrderDecision':
            return true;
        default:
            return false;
    }
}

const parseReasonToActionNeeded = (reason: string) => {
    switch (reason) {
        case 'DELTA SATISFIED':
            return false;
        default:
            return true;
    }
}

const getPositionRemoteHealth = (position: JediPortfolioPosition) => {
    const localQuantity = position.localPosition?.quantity || 0;
    const remoteQuantity = position.remotePosition?.quantity || 0;
    const manualQuantity = position.manualPosition?.quantity || 0;
    const pilotedQuantity = _.sumBy(position.pilotedPositions, 'quantity') || 0;

    const deltaThreshold = 0.0001; // 0.01% in decimal form

    const localVsRemoteDelta = Math.abs(localQuantity - remoteQuantity);
    const localVsPilotedAndManualDelta = Math.abs(localQuantity - (pilotedQuantity + manualQuantity));

    const localVsRemoteHealth = localVsRemoteDelta <= deltaThreshold * localQuantity;
    const localVsPilotedAndManualHealth = localVsPilotedAndManualDelta <= deltaThreshold * localQuantity;

    const bothExist = localQuantity > 0 && remoteQuantity > 0;
    const overallHealth = localVsRemoteHealth && localVsPilotedAndManualHealth && bothExist;

    const deltas = {
        localVsRemote: localQuantity - remoteQuantity,
        localVsPilotedAndManual: localQuantity - pilotedQuantity - manualQuantity,
    };

    return { overallHealth, localVsRemoteHealth, localVsPilotedAndManualHealth, bothExist, deltas };
};

export const BalancingDecisionsTable: React.FC<Props> = ({ userKey, autoPilotSettingsKey, positions, portfolioDetails }) => {

    const { portfolioKey, brokerName, connectionStatus } = portfolioDetails;

    const { data, loading, error } = useQueryAsUser(balancingDecisionsQuery, { userKey, variables: { userKey, autoPilotSettingsKey } });
    const decisions: BalancingDecision[] = data?.balancingDecisionsGet?.balancingDecisions ?? [];
    const response = useQueryAsUser(jediPortfolioPositionsGet, { userKey, portfolioKey, variables: { userKey, portfolioKey } });
    const positionData: JediPortfolioPosition[] = response.data?.jediPortfolioPositionsGet?.positions;
    const parameterData = data?.balancingDecisionsGet?.balancingParameters;
    const overAllocatedAmount = data?.balancingDecisionsGet?.overAllocatedAmount;
    const parameters: BalancingParameters = parameterData ? JSON.parse(parameterData) : {};
    const standardConnectionState = 
        connectionStatus === CONNECTION_STATUS.CONNECTED 
        && (
            (
                [BROKER_NAME.FIDELITY, BROKER_NAME.SCHWAB].includes(brokerName)
                && (
                    parameters?.brokerParameters?.fundsAvailable !== 0 
                    && parameters?.brokerParameters?.fundsAvailable !== 100000000
                )
            )
        || (![BROKER_NAME.FIDELITY, BROKER_NAME.SCHWAB].includes(brokerName))
        );
    const positionError = response.error;
    const positionLoading = response.loading;

    const [resolveMutation] = useMutationCoordinate(jediResolveOverAllocation, { variables: { autoPilotSettingsKey: autoPilotSettingsKey } });

    const handleResolve = async () => {
        try {
            await resolveMutation();
        } catch (e) {
            console.error(e);
        }
        window.location.reload();
    }


    const getAutopilotCash = gql`
    query slaveAutoPilotPortfoliosGet ($autoPilotSettingsKey: Int!){
        slaveAutoPilotPortfoliosGet( 
            input:{
            autoPilotSettingsKey: $autoPilotSettingsKey,
        }
    ) {
            slaveAutoPilotPortfolios {
                autoPilotCashHolding {
                    marketValue
                    percentOfPortfolio
                }
            }
        }
    }`;


    const autopilotCash = useQueryAsUser(getAutopilotCash, { userKey, variables: { userKey, autoPilotSettingsKey } });
    const pilotMarketValue = autopilotCash?.data?.slaveAutoPilotPortfoliosGet?.slaveAutoPilotPortfolios[0]?.autoPilotCashHolding?.marketValue;
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching data</div>;
    }

    const columns: GridColDef[] = [
        { 
            field: '', 
            headerName: 'Data Health', 
            width: 100,
            description: 'The health of the position in terms of equality between local and remote positions',
            renderCell: (params: GridRenderCellParams) =>  {
                if(positionLoading) {
                    return <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                  }}><Skeleton width={25} variant='rounded' sx={{ fontSize: '1rem' }} /></div>
                }
                if(!standardConnectionState) {
                    return <Tooltip title={`${brokerName} Unauthorized - customer needs to open the Autopilot app`}><AlertIcon color='error' style={{ verticalAlign: 'middle'}} /></Tooltip>
                }
                if(positionError) return <Tooltip title='Error Loading Position Health'><AlertIcon color='error' style={{ verticalAlign: 'middle'}} /></Tooltip>
                const portfolioPosition = positionData.find((p) => p.symbol === params.row.symbol);
                const positionHealth = portfolioPosition ? getPositionRemoteHealth(portfolioPosition) : { overallHealth: false, localVsRemoteHealth: false, localVsPilotedAndManualHealth: false, bothExist: false, deltas: { localVsRemote: 0, localVsPilotedAndManual: 0 } };
                const positionDataIsHealthy = positionHealth.overallHealth;
                // find which health is not healthy and put into a nice descriptive string
                const healthReasons = [];
                if (!positionHealth.localVsRemoteHealth) healthReasons.push(`local vs remote ${positionHealth.deltas.localVsRemote}`);
                if (!positionHealth.localVsPilotedAndManualHealth) healthReasons.push(`local vs piloted + manual ${positionHealth.deltas.localVsPilotedAndManual}`);
                if (!positionHealth.bothExist) healthReasons.push('missing remote or local position');
                return (
                <Tooltip title={!positionDataIsHealthy ? `Unhealthy: ${healthReasons.join(', ')}` : 'Healthy'}>
                    <div>
                        {positionDataIsHealthy && <DoneIcon color='success' style={{ verticalAlign: 'middle'}} />}
                        {!positionDataIsHealthy && <AlertIcon color='error' style={{ verticalAlign: 'middle'}} />}
                    </div>
                </Tooltip>)
            }
        },
        { 
            field: 'actionNeeded', 
            headerName: 'Eq Health',
            description: 'Health of position as compared to the allocations of the pilot',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip title={params.value ? `Needs to ${_.capitalize(params.row.orderSide)} - ${params.row.reason}` : params.row.reason.split('_').map(_.capitalize).join(' ')}>
                        <div>
                            {!params.value && <DoneIcon color='success' style={{ verticalAlign: 'middle'}} />}
                            {params.value && <AlertIcon color='error' style={{ verticalAlign: 'middle'}} />}
                        </div>
                    </Tooltip>
                )
            }
        },
        { 
            field: 'orderSide', 
            width: 150,
            headerName: 'Action',
            renderCell: (params: GridRenderCellParams) => (
                params.row.actionNeeded &&
                standardConnectionState && <Tooltip title={params.value.split('_').map(_.capitalize).join(' ')}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', color: params.row.typeColor }}>
                        {params.value === 'BUY' && <TrendingUpOutlinedIcon color='success'/>}
                        {params.value === 'SELL' && <TrendingDownOutlinedIcon color='error'/>}
                        <span>{params.value.split('_').map(_.capitalize).join(' ')} {_.round(params.row.amount, 2)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                    </div>
                </Tooltip>
            )
        },
        { 
            field: 'symbol', 
            headerName: 'Symbol', 
            width: 150, 
            align: 'left',
            renderCell: (params) => (
                <StockSymbolComponent logo={params.row.assetLogo} symbol={params.row.symbol} value={params.value} />
            )
        },
        { field: 'name', headerName: 'Name', width: 120 },
        { field: 'quantity', headerName: 'Current Quantity', width: 120, valueFormatter: (val) => _.round(val, 2)},
        { field: 'percentOfPortfolio', headerName: '% of Portfolio', width: 150, renderCell: (params) => `${Math.round(params.value * 100 * 100) / 100}%`},
        { field: 'marketValue', headerName: 'Current Value', width: 150, valueFormatter: (val: number) => val?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) },
    ];

    const rows = _.orderBy(decisions.map((decision: BalancingDecision) => {
        return {
            id: decision.symbol,
            symbol: decision.symbol,
            positionType: decision.positionType,
            orderSide: decision.orderSide,
            actionNeeded: parseReasonToActionNeeded(decision.reason),
            reason: decision.reason,
            decisionQuantity: decision.quantity,
            assetLogo: decision?.asset?.pictureUrl,
            amount: decision.quantity * decision?.asset?.currentPrice,
            name: positions.find(p => p.symbol === decision.symbol)?.name,
            quantity: positions.find(p => p.symbol === decision.symbol)?.quantity,
            percentOfPortfolio: positions.find(p => p.symbol === decision.symbol)?.percentOfPortfolio,
            marketValue: positions.find(p => p.symbol === decision.symbol)?.marketValue
        };
    }), 'amount', 'desc');

    const needsToAddBuyingPower = standardConnectionState && _.sum(rows.filter((r) => r.actionNeeded).map(r => r.orderSide === 'SELL' ? r.amount * -1 : r.amount)) > parameters?.brokerParameters?.fundsAvailable; 

    return (
        <div style={{ height: 400, width: '100%' }}>
            <Typography variant="h6" component="div">
                Piloted Positions
                <InfoOutlined onClick={handleOpen} fontSize='small' color='primary' style={{ verticalAlign: 'middle', marginLeft: '5px' }} />
            </Typography>
            <Typography variant="subtitle1">
                Total Market Value: <b>{_.round(_.sumBy(positions, d => d.marketValue), 2)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }</b>
                &nbsp;&nbsp;&nbsp;
                Buying Power: <b>{parameters?.brokerParameters?.fundsAvailable?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }</b>
                {(needsToAddBuyingPower && <Tooltip title='Not enough buying power'><AlertIcon color='error' style={{ verticalAlign: 'middle'}} /></Tooltip>)}
                {(!standardConnectionState && <Tooltip title={`${brokerName} Unauthorized - customer needs to open the Autopilot app`}><AlertIcon color='error' style={{ verticalAlign: 'middle'}} /></Tooltip>)}
                &nbsp;&nbsp;&nbsp;
                Autopilot Cash: {<b>{pilotMarketValue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }</b>}
            </Typography>
            {
                (overAllocatedAmount > 1 && standardConnectionState && connectionStatus === CONNECTION_STATUS.CONNECTED) && 
                <Typography variant="subtitle1" color='error'>
                    Over allocated by {overAllocatedAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
                    &nbsp;&nbsp;&nbsp;
                    <React.Fragment>
                        <ConfirmAlertDialog
                            title='Are you sure you want to resolve the over allocation?'
                            description='This action will subtract the over allocated amount from the autopilot allocation.'
                            actionButtonTitle='Resolve'
                            onConfirm={handleResolve}
                            buttonComponent={
                                <Button 
                                    size='small' 
                                    variant="outlined" 
                                    color="error" 
                                    role={undefined}
                                >
                                    Resolve
                                </Button>
                            }
                        />
                    </React.Fragment>
                </Typography>
            }
            <Divider sx={{ my: 1 }} />
            <DataGrid 
                rowHeight={40}
                columns={columns} 
                rows={rows} 
                loading={loading}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
                PaperProps={{
                    style: {
                        maxHeight: '80vh',
                        overflow: 'auto',
                    },
                }}
                >
                <Typography id="modal-description" sx={{ mt: 2 }}>
                    <pre style={{fontSize: 10}} >{JSON.stringify(parameters.brokerParameters, null, 2)}</pre>
                </Typography>
            </Dialog>
        </div>
        
    );
};