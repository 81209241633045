import {Divider, IconButton, Tooltip, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import React from "react";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import moment from "moment/moment";
import InfoIcon from "@mui/icons-material/Info";
import {gql} from "@apollo/client";
import DoneIcon from "@mui/icons-material/Done";
import AlertIcon from "@mui/icons-material/NotificationImportant";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {useNavigate} from "react-router-dom";


export interface portfolioEventsTableProps {
    portfolioKey: number;
};

interface PortfolioEvent{
    status: string,
    portfolioEventKey: string,
    type: string,
    autoPilotSettingsKey: string,
    reason: string,
    amount: string,
    createdAt: string
}


export default function PortfolioEventsTable ({ portfolioKey } : portfolioEventsTableProps) {

    const goTo = useNavigate();
    const portfolioEventsGetQuery = gql`
    query portfolioEventsGet(
        $portfolioKey: Int! ){ 
        portfolioEventsGet( input: { portfolioKey: $portfolioKey } ) {
            portfolioEvents {
                portfolioEventKey
                status
                type
                portfolioKey
                amount
                createdAt
                reason
                autoPilotSettingsKey
            }
        }
    }`;

    const { data, loading, error } = useQueryCoordinate(portfolioEventsGetQuery, { variables: { portfolioKey } });
    const events = data?.portfolioEventsGet?.portfolioEvents

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error fetching data</div>;
    }

    const columns: GridColDef[] = [
        {
            field: 'status',
            headerName: 'Status',
            width: 70,
            renderCell: (params) => {
                switch (params.row.status) {
                    case 'COMPLETED':
                        return <Tooltip title={params.row.status}><DoneIcon color='success' style={{ verticalAlign: 'middle'}} /></Tooltip>
                        break;
                    case 'FAILED':
                        return <Tooltip title={params.row.status}><AlertIcon color='error' style={{ verticalAlign: 'middle'}} /></Tooltip>
                        break;
                    case 'IN_PROGRESS':
                        return <Tooltip title={params.row.status}><HourglassTopIcon color='action' style={{ verticalAlign: 'middle'}} /></Tooltip>
                        break;
                    case 'INITIATED':
                        return <Tooltip title={params.row.status}><PlayCircleOutlineIcon color='action' style={{ verticalAlign: 'middle'}} /></Tooltip>
                        break;
                    default:
                        return 'null'
                }
            }
        },
        {   field: 'type', headerName: 'Type', width: 150,},
        {   field: 'autoPilotSettingsKey',
            headerName: 'Pilot Details',
            width: 110,
            renderCell: (params) => {
                return (
                    <IconButton onClick={() => goTo(`/pilot-details/${params.row.autoPilotSettingsKey}`)}>
                        <PersonSearchIcon />
                    </IconButton>
                )
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 100,
            renderCell: (params) => {
                const amount = params.row.amount ? params.row.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD', }) : 'N/A'
                return <div>{amount}</div>
            }
        },
        {   field: 'created',
            headerName: 'Initialized At',
            width: 155,
            valueFormatter: (value) => value ? moment(value).format('MM/DD/YYYY HH:mm') : 'N/A'
        },
        {   field: 'reason',
            headerName: 'Reason',
            width: 150,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.reason && (
                            <Tooltip title={params.row.reason}>
                                <InfoIcon style={{verticalAlign: "middle"}}/>
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
    ];

    const rows = events.map((row: PortfolioEvent) => {
        return {
            id: row.portfolioEventKey,
            status: row.status,
            type: row.type,
            autoPilotSettingsKey: row.autoPilotSettingsKey,
            reason: row.reason,
            amount: row.amount,
            created: row.createdAt
        }
    })

    const handleRowClick = (eventKey: string) => {
        goTo(`/portfolio-event/${eventKey}`);
    };

    return (
        <div style={{height: 400, width: '100%'}}>
            <Typography variant="h6" component="div">
                Portfolio Events
            </Typography>
            <Divider sx={{my: 1}}/>
            <DataGrid
                onRowClick={(params) => handleRowClick(params.row.id)}
                rows={rows}
                columns={columns}
                rowHeight={40}
            />
        </div>
    )
}