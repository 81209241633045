import { gql } from '@apollo/client';
import { BROKER_NAME, CONNECTION_STATUS } from '../../../shared/portfolio-types';

export const listPilotsQuery = gql`
    query autoPilotSettingsGet {
        autoPilotSettingsGet(input: { setupStatuses: [DELETED, INITIATED, ACTIVE, PAUSED, SHORT_CIRCUITED] }) {
            autoPilotSettings {
                autoPilotSettingsKey
                setupStatus
                slavePortfolio {
                    portfolioName
                    brokerName
                    connectionStatus
                }
                masterUser {
                    firstName
                    lastName
                }
                allocationAmount
                amountPending
                initiatedAt
                deletedAt
                executionType
            }
        }
    }
`;

export interface PilotData {
    autoPilotSettingsKey: number;
    setupStatus: string;
    deletedAt: Date;
    initiatedAt: Date;
    slavePortfolio: {
        portfolioName: string;
        brokerName: BROKER_NAME;
        connectionStatus: CONNECTION_STATUS;
    };
    masterUser: {
        firstName: string;
        lastName: string;
    };
    allocationAmount: number;
    amountPending: number;
    executionType: string
}