import React from 'react'; 
import { gql } from '@apollo/client'; 
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQueryAsUser, useQueryCoordinate } from '../../clients/graphql/graphql.hooks';
import { jediFailedAutopilotOrdersGet, JediFailedAutopilotOrder, JediFailedAutopilotOrdersResult } from '../../clients/graphql/portfolio/failed-autopilot-orders.query';
import {CircularProgress, Divider, Tooltip, Typography} from '@mui/material';
import moment from 'moment';
import InfoIcon from "@mui/icons-material/Info";
import {StockSymbolComponent} from "../../pages/users/StockSymbolComponent";

interface Props {
    userKey?: number;
    autoPilotSettingsKey?: number;
    portfolioKey?: number;
};

export const FailedAutopilotOrdersTable: React.FC<Props> = ({ userKey, autoPilotSettingsKey, portfolioKey }) => {
    const { data, loading, error } = useQueryAsUser(jediFailedAutopilotOrdersGet, { variables: { userKey, autoPilotSettingsKey, portfolioKey, limit: 100, offset: 0 } });
    const orders = data?.jediFailedAutopilotOrdersGet?.autoPilotOrders;

    if (loading) {
        return <CircularProgress sx={{marginLeft: 'calc(50% - 10px)'}}/>
    }
    if (error) {
        return <div>Error fetching data</div>;
    }

    const columns: GridColDef[] = [
        { 
            field: 'pictureUrl', 
            headerName: 'Asset', 
            width: 90,
            renderCell: (params) => (
                <StockSymbolComponent symbol={params.row.symbol} logo={params.row.pictureUrl} value={params.row.symbol} />
            )
        },
        { field: 'orderSide', headerName: 'Order Side', width: 90 },
        { field: 'quantity', headerName: 'Quantity', width: 90 },
        { field: 'averagePrice', headerName: 'Price', width: 90 },
        { field: 'createdAt', headerName: 'Created At', width: 150, valueFormatter: (value) => value ? moment(value).format('MM/DD/YYYY HH:mm') : '' },
        {   field: 'failureReason',
            headerName: 'Failure Reason',
            width: 150,
            renderCell: (params) => (
                <div>
                    <Tooltip title={params.row.failureReason == null ? 'none' : params.row.failureReason}>
                        <InfoIcon style={{verticalAlign: "middle"}} />
                    </Tooltip>
                </div>
            ),
        } ,
    ];

    const rows = orders.map((row: JediFailedAutopilotOrder, index: number) => {
        return {
            id: index, 
            pictureUrl: row.asset.pictureUrl,
            ...row,
        } 
    });

    return (
        <div style={{ height: 400, width: '100%' }}>
            <Typography sx={{
                color: '#6D6D6D',
                font: 'Roboto',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '12px',
                letterSpacing: '0.05em',
                marginTop: '3.5rem',
                marginLeft: '1rem',
                paddingBottom: '.75rem',
            }}>
                FAILED AUTOPILOT ORDERS
            </Typography>
            <DataGrid 
                rows={rows} 
                columns={columns}
                rowHeight={40}
                sx = {{marginLeft: '1rem', marginRight: '1rem'}}
            />
        </div>
    );
};
