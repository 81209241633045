import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useQueryAsUser, useQueryCoordinate } from "../../clients/graphql/graphql.hooks";
import { jediPortfolioRestrictionsGet, PortfolioRestriction } from "../../clients/graphql/portfolio/portfolio-restrictions.query";
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, Key } from "react";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";

export const PortfolioRestrictionsTable: React.FC<{ userKey: number, portfolioKey: number}> = ({ userKey, portfolioKey }) => {
    const response = useQueryAsUser(jediPortfolioRestrictionsGet, { userKey, portfolioKey, variables: { userKey, portfolioKey } });
    const data = response.data?.portfolioGet?.portfolio?.restrictions || [];
    const sorted = [...data].sort((a: PortfolioRestriction, b: PortfolioRestriction) => a?.asset?.symbol.localeCompare(b?.asset?.symbol));
    const error = response.error;
    const loading = response.loading;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching data</div>;
    }
    
    return <>
        <Typography variant="h6" component="div">
            Portfolio Restrictions
        </Typography>
        <Table>
            <TableHead>
            <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Reason</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {[...sorted]
                .map((row: PortfolioRestriction) => (
                    <TableRow key={`${row.asset?.symbol}-${row.createdAt}`}>
                        <TableCell align="right">{row.asset?.symbol}</TableCell>
                        <TableCell align="right">{moment(row.createdAt).format('MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="right">
                            {row.reason || <i>unknown</i>}
                            <Tooltip title={portfolioRestrictionExplination(row.reason) || "unknown"}>
                                    <InfoIcon style={{verticalAlign: "middle"}} />
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </>
}

export const portfolioRestrictionExplination = (reason: string) => {
    switch (reason) {
        case 'UNSIGNED_DESIGNATED_INVESTMENTS_AGREEMENT':
            return `
                Security is unable to be traded without the customer
                signing a Designated Investment Agreement in their
                brokerage account
            `;
        default:
            return "Unknown";
    }
}