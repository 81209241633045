import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { TriggerHistoryItem, pilotTriggerHistoryQuery, triggerHistoryOrdersQuery } from "../../clients/graphql/user-pilots/pilot-trigger-history.query";
import { useQueryAsUser } from '../../clients/graphql/graphql.hooks';
import BuyIcon from '@mui/icons-material/TrendingUp';
import SellIcon from '@mui/icons-material/TrendingDown';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import { Dialog, Divider, Modal, Tooltip, Typography } from '@mui/material';
import { TriggerHistoryOrdersTable } from './TriggerHistoryOrdersTable';
import { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import LeakAddOutlinedIcon from '@mui/icons-material/LeakAddOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import * as _ from 'lodash';
import moment from 'moment';

const buys = ['RECURRING_INVESTMENT', 'MANUAL_DEPOSIT'];
const sells = ['MANUAL_WITHDRAW', 'MASTER_DELETED'];
const trades = ['MASTER_TRADED'];
const rebalance = ['REBALANCE'];

const renderCellWithIcon = (params: GridRenderCellParams) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', color: params.row.typeColor }}>
        {buys.includes(params.row.type) && <BuyIcon />}
        {sells.includes(params.row.type) && <SellIcon />}
        {trades.includes(params.row.type) && <AlertIcon />}
        {rebalance.includes(params.row.type) && <AutorenewOutlinedIcon />}
        <span>{params.value.split('_').map(_.capitalize).join(' ')}</span>
    </div>
);
const triggerStatus = () => {
    return { 
        field: 'triggerStatus', 
        headerName: 'Status',
        headerClassName: 'table-header',
        width: 70,
        renderCell: (params: GridRenderCellParams) => {
            let icon;
            switch (params.value) {
                case 'PENDING':
                    icon = <MoreHorizOutlinedIcon style={{verticalAlign: 'middle', color: 'orange'}}/>;
                    break;
                case 'COMPLETE':
                    icon = <TaskAltOutlinedIcon style={{color: '#4caf50', verticalAlign: 'middle'}} />;
                    break;
                case 'FAILED':
                    icon = <ErrorIcon color='error' style={{verticalAlign: 'middle'}} />;
                    break;
                default:
                    icon = null;
                    break;
            }
            return (
                <Tooltip title={_.capitalize(params.value)}>
                    <span style={{ alignItems: 'baseline' }}>
                        {icon}
                    </span>
                </Tooltip>
            );
        } 
    };
};
const columns: GridColDef[] = [
    triggerStatus(),
    {
        field: 'type',
        headerName: 'Trigger',
        width: 180,
        renderCell: renderCellWithIcon,
        description: 'The type of trigger',
    },
    {
        field: 'transactionAmount',
        valueFormatter: (value) => value ? `$${value}` : '',
        headerName: 'Amount',
        width: 100,
        description: 'Total dollar amount transacted',
        type: 'number',
        align: 'right',
    },
    {
        field: 'totalInvested',
        valueFormatter: (value) => value ? `$${value}` : '',
        headerName: 'Net Investment',
        width: 115,
        description: 'Net Amount Invested',
        type: 'number',
        align: 'right',
    },
    {
        field: 'tradeCount',
        headerName: 'Trades',
        width: 70,
        description: 'Number of trades executed',
        type: 'number',
        align: 'center',
    },
    {
        field: 'createdAt',
        headerName: 'Date',
        width: 190,
        description: 'When the trigger was executed',
        type: 'dateTime',
        valueFormatter: (value) => moment(value).format('MM/DD/YYYY HH:mm:ss A'),
    },
    {
        field: 'portfolioName',
        headerName: 'Pilot',
        width: 150,
        description: 'The pilot that executed the trigger',
    },
];

interface TriggerHistoryTableProps {
    info: {
        userKey: number;
        autoPilotSettingsKey: number;
        allocationAmount: number;
    }
}

export const TriggerHistoryTable: React.FC<TriggerHistoryTableProps> = ({ info }) => {
    const { userKey, autoPilotSettingsKey, allocationAmount } = info;
    const [selectedRow, setSelectedRow] = useState<TriggerHistoryItem | null>(null);
    const handleRowClick = (params: GridRowParams) => {
        const selectedTrigger = details.find((item) => item.triggerHistoryKey === params.row.triggerHistoryKey);
        if (selectedTrigger) {
            setSelectedRow(selectedTrigger);
        }
    };

    const { data, loading, error } = useQueryAsUser(pilotTriggerHistoryQuery, { userKey, autoPilotSettingsKey, variables: { userKey, autoPilotSettingsKey, limit: 100, offset: 0 } });
    
    if(loading) return <div>Loading...</div>;
    if(error) return <div>Error: {error.message}</div>;
    const details: TriggerHistoryItem[] = data?.triggerHistoryGet.map((item: TriggerHistoryItem) => ({
        ...item,
        typeColor: buys.includes(item.type) ? '#4caf50' : sells.includes(item.type) ? 'orange' : '#2196F3',
    })) ?? [];

    const rows = details.map((row, index) => { 
        const failedTrades = row.triggerHistoryOrders.filter(order => order.orderStatus === 'FAILED');
        const pendingTrades = row.triggerHistoryOrders.filter(order => order.orderState === 'PENDING');
        // Group orders by symbol
        const groupedOrders = _.groupBy(row.triggerHistoryOrders, 'symbol');
        // Find symbols with only failed orders
        const symbolsWithOnlyFailures = Object.keys(groupedOrders).filter(symbol => {
            const symbolOrders = groupedOrders[symbol];
            return !symbolOrders.some(order => order.orderState === "FILLED" && order.orderStatus === "SENT_TO_BROKER");
        });
        return {
            ...row,
            id: index,
            triggerStatus: row.triggerStatus, // symbolsWithOnlyFailures.length > 0 ? 'FAILED' : pendingTrades.length > 0 ? 'PENDING' : 'COMPLETE',
            portfolioName: row.masterPortfolio?.portfolioName,
            totalInvested: _.round(_.sumBy(row.triggerHistoryOrders, (order) => {
                if(order.orderState === 'FILLED') {
                    const amount = order.orderSide === 'BUY' ? order.transactionAmount : -order.transactionAmount;
                    return amount;
                } else {
                    return 0;
                }
            }), 2),
        }
    });

    const actualNetInvested = _.sumBy(rows, 'totalInvested');

    return (
        <>
        <Typography variant="subtitle1" component="div" align="left">
            TRANSACTIONS
        </Typography>
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                rowCount={data?.triggerHistoryGet.length ?? 0}
                loading={loading}
                onRowClick={handleRowClick}
                rowHeight={40}
                hideFooter={true}
            />
            {selectedRow && (
                <Dialog
                    open={selectedRow !== null} 
                    onClose={() => setSelectedRow(null)}
                    fullWidth={true}
                    maxWidth="lg"
                    PaperProps={{
                        style: {
                            maxHeight: '80vh',
                            overflow: 'auto',
                        },
                    }}
                >
                    <TriggerHistoryOrdersTable 
                        props={details.find(d => d.triggerHistoryKey === selectedRow.triggerHistoryKey)?.triggerHistoryOrders || []} 
                        targetAmount={selectedRow.transactionAmount}
                    />
                </Dialog>
            )}
        </div>
        </>
    );
};