import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper, styled, Typography,} from '@mui/material';
import {UserWarningList} from '../../views/user/UserWarningsList';
import {LegacyAdminButton} from '../../components/buttons/LegacyAdminButton';
import {HomeScreen} from '../../views/mobile/home/home-screen'
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {GQL_WhoamiWithSubscriptions} from "../../clients/graphql/user/user-info.query";
import {UserCrudPanel} from "../../views/user/UserCrudPanel";
import {UserPilotAndTransactionBox} from "../../views/user/UserPilotAndTransactionBox";
import DeleteUserButton from "../../components/buttons/DeleteUserButton";
import {DownloadTradesButton} from "../../components/buttons/DownloadTradesButton";
import {ZendeskUserButton} from "../../components/buttons/ZendeskUserButton";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 10,
}));




export function SlaveDetailHoldings(){
    const { userKey } = useParams();
    const [slavePortfolio, setSlavePortfolio] = useState('');
    const [slavePerformanceData, setSlavePerformanceData] = useState('');
    const [currentOption, setCurrentOption] = useState("day");
    const current = slavePerformanceData ? slavePerformanceData[currentOption]?.slavePerformance : null;

    const slaveDataByAutopilotSetting = current?.find(
        (f) => f?.autoPilotSettingsKey === slavePortfolio?.autoPilotSettingsKey
    )

    useEffect(() => {
        setSlavePortfolio('');
        setSlavePerformanceData('');
    }, [userKey]);

    return(
            <HomeScreen
                userKey={userKey}
                setParams={(slave, performance) => {
                    setSlavePortfolio(slave);
                    setSlavePerformanceData(performance);
                }}
                onChangeOptionHome={(f) => {
                    setCurrentOption(f);
                }}
                currentOptionHome={currentOption}
                slavePortfolio={slavePortfolio}
                slaveDataByAutopilotSetting={slaveDataByAutopilotSetting}
            />
    );
}


export function ViewUserPage() {
    const { userKey } = useParams();
    const {  data } = useQueryAsUser(GQL_WhoamiWithSubscriptions, { userKey, variables: { userKey } })
    const firstName = data?.whoami.firstName;
    const lastName = data?.whoami.lastName;
    return <Box>

        <Box display={"flex"} gap={2}>
            <Typography variant="h4">{firstName} {lastName}</Typography>
            <LegacyAdminButton path={`/users/${userKey}`} />
            <DeleteUserButton userKey={userKey}/>
            <DownloadTradesButton userKey={userKey}/>
            <ZendeskUserButton userId={userKey} />
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <UserWarningList userKey={userKey} />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={2.5}>
                <Item>
                    <UserCrudPanel userKey={userKey} />
                </Item>
            </Grid>
            <Grid item xs={6}>
                <Item>
                    <UserPilotAndTransactionBox info={{ userKey: parseInt(userKey) }}/>
                </Item>
            </Grid>
            <Grid item xs={3.5}>
                <Item>
                    <SlaveDetailHoldings/>
                </Item>
            </Grid>
        </Grid>
    </Box>
}