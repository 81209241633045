import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Paper, styled, } from '@mui/material';
import { PortfolioPositionsTable } from '../../components/tables/PortfolioPositionsTable';
import { useQueryCoordinate } from '../../clients/graphql/graphql.hooks';
import { whoisit } from '../../clients/graphql/user/whoisit.query';
import { InstitutionAuthLogsTable } from '../../components/tables/InstitutionAuthLogsTable';
import { FailedAutopilotOrdersTable } from '../../components/tables/FailedAutopilotOrdersTable';
import UserHistoricalValueChart from './UserHistoricalValueChart';
import PortfolioEventsTable from "../../components/tables/PortfolioEventsTable";
import PortfolioCrudPanel from "../../views/portfolios/PortfolioCrudPanel";
import PortfolioDetailsPanel from "../../views/portfolios/PortfolioDetailsPanel";
import { PortfolioRestrictionsTable } from '../../components/tables/PortfolioRestrictionsTable';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginTop: 10,
  minHeight: 'calc(100% - 25px)',
}));

export function UserPortfolioPage() {
    const { portfolioKey } = useParams();
    const intPortfolioKey = parseInt(portfolioKey as string);
    const { data, loading, error } = useQueryCoordinate(whoisit, { variables: { portfolioKey: intPortfolioKey } });
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error fetching data</div>;
    }
    const userKey = data?.whoisit?.user?.userKey;
    const parsedUserKey = parseInt(userKey as string);
    const institutionKey = parseInt(data?.whoisit?.institution?.institutionKey as string);

    return (
        <Box>
            <Typography variant="h4" paddingBottom={4}>Portfolio: {portfolioKey}</Typography>
            <Grid container spacing={2} sx={{height: 525}}>
                <Grid item xs={3.3} sx={{ height: '100%' }}>
                    <Item>
                        <PortfolioCrudPanel portfolioKey={intPortfolioKey}/>
                    </Item>
                </Grid>
                <Grid item xs={2.7} sx={{ height: '100%' }}>
                    <Item>
                        <PortfolioDetailsPanel portfolioKey={intPortfolioKey}/>
                    </Item>
                </Grid>
                <Grid item xs={6} sx={{ height: '100%' }}>
                    <Item sx={{ height: 'calc(100% - 25px)' }}>
                        <UserHistoricalValueChart portfolioKey={intPortfolioKey}/>
                    </Item>
                </Grid>

            </Grid>
            <Grid container spacing={2} sx={{ height: 500 }}>
                <Grid item xs={6} sx={{ height: '100%' }}>
                    <Item>
                        <PortfolioPositionsTable userKey={parsedUserKey} portfolioKey={intPortfolioKey} />
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <InstitutionAuthLogsTable institutionKey={institutionKey} />
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ height: 500 }}>
                <Grid item xs={6} sx={{ height: '100%' }}>
                    <Item>
                        <FailedAutopilotOrdersTable userKey={parsedUserKey} portfolioKey={intPortfolioKey} />
                    </Item>
                </Grid>
                <Grid item xs={6} sx={{ height: '100%' }}>
                    <Item>
                        <PortfolioEventsTable portfolioKey={intPortfolioKey}/>
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ height: 500 }}>
                <Grid item xs={6} sx={{ height: '100%' }}>
                    <Item>
                        {/* <PortfolioRestrictionsTable userKey={parsedUserKey} portfolioKey={intPortfolioKey} /> */}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}