import { Button, Tooltip } from "@mui/material";
import WifiOffIcon from '@mui/icons-material/WifiOff';

export function DisconnectedStatus() {
  return <Button
    component="label"
    variant="text"
    tabIndex={-1}
    color='error'
    startIcon={
      <Tooltip title="Institution is disconnected">
        <WifiOffIcon />
      </Tooltip>
    }
  >
    Disconnected
  </Button>
}