import React, {useState} from 'react';
import './css/home-screen-style.css';
import Draggable from 'react-draggable';
import SlavePortfolioCard from './components/slave-portfolio-card';
import SlavePortfolioHeader from './components/slave-portfolio-header';
import {Box, Typography} from '@mui/material';
import {getSlavePortfoliosQuery} from '../../../clients/graphql/user-pilots/user-pilot-details.query';
import {WarningCarousel} from './components/warning-carousel'
import {allPerformanceSpansGetQuery} from '../../../clients/graphql/user-pilots/slave-performance-for-span.query';
import {useQueryAsUser} from '../../../clients/graphql/graphql.hooks';
import {SlaveDetailScreen} from "../detail/slave-detail-screen";

export const HomeScreen = ({ userKey, setParams, onChangeOptionHome, currentOptionHome, slavePortfolio, slaveDataByAutopilotSetting}) => {
    const { data: dataSlave, loading: loadingSlave, error: errorSlave } = useQueryAsUser(getSlavePortfoliosQuery, { userKey,appVersionEnabled:true , variables: { userKey } });
    const { data: dataPerformance, loading: loadingPerformance, error: errorPerformance } = useQueryAsUser(allPerformanceSpansGetQuery, { userKey, appVersionEnabled:true , variables: { userKey } });

    const [dragging, setDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState(0);

    const slavePerformanceData = dataPerformance ? dataPerformance[currentOptionHome].slavePerformance : null;

    const handleDrag = (e, data) => {
        setDragging(true);
        setDragOffset(data.y);
    };

    const handleStop = () => {
        if (dragOffset > 10) {
            setTimeout(() => {
                setDragging(false);
            }, 10); // Slight delay to distinguish between drag and click
        } else {
            setDragging(false);
        }

        setDragOffset(0);
    };

    const handleCardClick = (slave, dataPerformance, e) => {
        if (dragging) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            setParams(slave, dataPerformance);
        }
    };

    const numberOfPortfolios = dataSlave?.slaveAutoPilotPortfoliosGet.slaveAutoPilotPortfolios.length;
    const cardHeight = (numberOfPortfolios * 150).toString() + 'px';

    return (
        <Box>
            <Typography variant="subtitle1" align={"left"}>CUSTOMER VIEW</Typography>
            <div className="home-view" style={{height: cardHeight}}>
                {loadingPerformance ? (
                    <div>Loading</div>
                ) : errorPerformance ? (
                    <div>Some error happens</div>
                ) : (
                    <SlavePortfolioHeader
                        isLoading={loadingPerformance}
                        isError={errorPerformance}
                        data={dataPerformance}
                        currentOption={currentOptionHome}
                        onChangeOption={onChangeOptionHome}
                        dataSlave={dataSlave?.slaveAutoPilotPortfoliosGet}
                    />
                )}
                <WarningCarousel userKey={userKey} depositFailures={
                    dataSlave?.slaveAutoPilotPortfoliosGet.slaveAutoPilotPortfolios.filter((data,i)=> (data.failedTransferData?.amount??0.0  !== 0.0))??[]
                } />
                <div style={{ height: 10 }}></div>
                <Draggable
                    axis="y"
                    bounds="parent"
                    onDrag={handleDrag}
                    onStop={handleStop}
                    position={{ x: 0, y: dragOffset }}
                >
                    <div className="cards-container">
                        {loadingSlave ? (
                            <div>Loading</div>
                        ) : errorSlave ? (
                            <div>Some error happens</div>
                        ) : (
                            dataSlave?.slaveAutoPilotPortfoliosGet.slaveAutoPilotPortfolios.map((slave, index) => {
                                const slaveDataByAutopilotSetting = slavePerformanceData?.find(
                                    (f) => f.autoPilotSettingsKey === slave.autoPilotSettingsKey
                                );
                                const result = slaveDataByAutopilotSetting
                                    ? `${slaveDataByAutopilotSetting?.profitLoss?.toFixed(2)}  (${(slaveDataByAutopilotSetting?.profitLossPercent * 100).toFixed(1)}%)`
                                    : '0.00 + (0.00%)';

                                const dragEffect = dragging ? dragOffset * (index * 0.4) : 0;

                                return (
                                    <div
                                        key={index}
                                        className="card-wrapper"
                                        style={{ zIndex: index + 1, transform: `translateY(${index * 75 + dragEffect}px)` }}
                                        onClick={(e) => handleCardClick(slave, dataPerformance, e)}
                                    >
                                        <SlavePortfolioCard
                                            title={slave.masterPortfolio.userFullName}
                                            subtitle={(slave.autoPilotSettings.setupStatus === 'INITIATED')? 'FREE PLAN' : ''}
                                            amount={(slave.pendingTransferData?.amount??0.0)!==0.0? (slave.pendingTransferData?.amount) : slave?.totalValue}
                                            isPendingTransfer={slave.pendingTransferData?.amount??0.0!==0.0}
                                            change={result}
                                            isLosingMoney={slaveDataByAutopilotSetting?.profitLoss < 0}
                                            imageSrc={slave.masterPortfolio.portfolioCardImageUrl}
                                            virtualText={slave.autoPilotSettings.slavePortfolio.portfolioName}
                                        />
                                    </div>
                                );
                            })
                        )}
                    </div>
                </Draggable>
            </div>
            <Typography variant="subtitle1" component="div" align={"left"}>
                PORTFOLIO HOLDINGS
            </Typography>
            <SlaveDetailScreen
                slavePortfolio={slavePortfolio}
                userKey={userKey}
                slaveDataByAutopilotSetting={slaveDataByAutopilotSetting}
            />
        </Box>
    );
};
