import {Button} from "@mui/material";
import React from "react";
import CachedIcon from '@mui/icons-material/Cached';
import {useMutationCoordinate} from "../../clients/graphql/graphql.hooks";
import {gql} from "@apollo/client";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import ConfirmAlertDialog from "../dialogs/confirm-alert";

export interface BalancePortfolioButtonProps {
    autoPilotSettingsKey: number,
}

export function BalancePortfolioButton({ autoPilotSettingsKey } : BalancePortfolioButtonProps){

    const jediBalance = gql`
    mutation{
        jediBalance(input: {autoPilotSettingsKey: 1}){
            success
            message
        }
    }`;

    const [balanceMutation, balanceResponse] = useMutationCoordinate(jediBalance, { variables: { autoPilotSettingsKey: autoPilotSettingsKey } });


    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <ConfirmAlertDialog
                title='Are you sure you want to re-balance?'
                description='Rebalancing will only happen during market hrs'
                actionButtonTitle='Re-enable'
                onConfirm={() => balanceMutation()}
                buttonComponent={
                    <Button
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                    startIcon={<CachedIcon />}
                style={{marginLeft: "10px", color: "green", borderColor: "green"}}
            >
                Balance Portfolio
            </Button>
                }
            />

            {balanceResponse?.data?.jediBalance?.success && (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{marginTop: '10px', width: "300px"}} variant="outlined" >
                    Balance is in progress.
                </Alert>
            )
            }

            {balanceResponse?.data?.jediBalance?.success === false && (
                <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error" sx={{marginTop: '10px', width: "300px"}} variant="outlined" >
                    There was an error with the request : {balanceResponse?.data?.jediBalance?.message}
                </Alert>
            )
            }
        </div>
    )
}