import {Button, CircularProgress, Dialog} from "@mui/material";
import React, {useState} from "react";
import {gql} from "@apollo/client";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import {useMutationCoordinate} from "../../clients/graphql/graphql.hooks";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

export interface DeleteUserButtonProps{
    userKey: string,
}

export default function DeleteUserButton({ userKey } : DeleteUserButtonProps) {

    const JediUserDeleteMutation = gql`
    mutation JediUserDelete(
        $hardDelete: Boolean!
        $userKey: Int!
    ){
        JediUserDelete(input:{
            hardDelete: $hardDelete,
            userKey: $userKey
        })  { user {
               firstName,
               lastName,
               userKey
            }
        }
    }`;

    const [deleteMutation, status] = useMutationCoordinate(JediUserDeleteMutation, { variables: { hardDelete: true, userKey: parseInt(userKey) } });
    let goTo = useNavigate();
    const [cancelCicked, setCancelClicked] = useState(false);
    const [input, setInput] = useState('');
    const [confirmError, setConfirmError] = useState(false)
    const [deleteSuccessful, setDeleteSuccessful] = useState(false)

    function confirmDelete(input : string){
        if(input === 'delete'){
            deleteMutation();
            setCancelClicked(false)
            setConfirmError(false)
            setInput('')
            setDeleteSuccessful(true);
        }else{
            setConfirmError(true)
        }
    }

    return (
        <div>
            <Button
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                color={"error"}
                size={"large"}
                onClick={() => setCancelClicked(true)}
            >
                Delete User
            </Button>

            {cancelCicked && (
                <Dialog
                    open={cancelCicked}
                    onClose={() => setCancelClicked(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Type 'delete' to confirm delete
                            {confirmError && (
                                <p style={{color: "red"}}>Confirm failed, try again</p>
                            )}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="delete"
                                label="Confirm delete"
                                name="delete"
                                autoComplete="delete"
                                onChange={(e) => setInput(e.target.value)}
                                value={input}
                                autoFocus
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCancelClicked(false)}>Nevermind</Button>
                        <Button onClick={() => confirmDelete(input)} autoFocus style={{color: "red"}}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                )
            }

            {status?.loading && (
                <Dialog
                    open={status?.loading}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />
                    </DialogContent>
                </Dialog>
            )}

            {deleteSuccessful && (
                <Dialog
                    open={deleteSuccessful}
                    onClose={() => goTo('/')}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <Typography sx={{margin: "auto", fontWeight: "bold"}} component="h1" variant="h4">
                            User Successfully Deleted
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
}