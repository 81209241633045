import React from 'react';
import '../css/slave-portfolio-card-style.css';
import { red } from '@mui/material/colors';

const SlavePortfolioCard = ({ title, subtitle, amount, change,isPendingTransfer, imageSrc, virtualText, isLosingMoney, onClick }) => {
    return (
        <div className="card" style={{ backgroundImage: `url(${imageSrc})` }} onClick={onClick}>
            <div className="card-content">
                <div className="card-header">
                    <div className="card-column-items alignItemsStart">
                        <h1 className="card-amount" style={{ color: isPendingTransfer ? 'gray' : 'white' }}>${(amount??0.0).toFixed(2)}</h1>
                        <p className="card-change" style={{ color: isLosingMoney ? 'red' : '#4caf50' }}>{isPendingTransfer?'Deposit Pending':change}</p>
                    </div>
                    <div className='card-column-items alignItemsEnd'>
                        <h2 className="card-title" >{title}</h2>
                        <p className="card-subtitle">{subtitle}</p>
                    </div>

                </div>
                <div className="card-footer">
                    <p className="virtual-text">{virtualText}</p>
                </div>
            </div>
        </div>
    );
};

export default SlavePortfolioCard;
