import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Typography, Grid, Paper, styled} from '@mui/material';
import {UserPilotDetailsOverview, UserPilotOverviewData} from '../../views/pilots/UserPilotDetailsOverview';
import {
    SlaveAutoPilotPortfolio,
    userPilotDetailsQuery,
} from '../../clients/graphql/user-pilots/user-pilot-details.query';
import {useQueryAsUser, useQueryCoordinate} from '../../clients/graphql/graphql.hooks';
import {TriggerHistoryTable} from '../../components/tables/TriggerHistoryTable';
import {LegacyAdminButton} from '../../components/buttons/LegacyAdminButton';
import {BalancingDecisionsTable} from '../../components/tables/BalancingDecisionsTable';
import {FailedAutopilotOrdersTable} from '../../components/tables/FailedAutopilotOrdersTable';
import {whoisit} from '../../clients/graphql/user/whoisit.query';
import {BalancePortfolioButton} from "../../components/buttons/BalancePortfolioButton";
import { PortfolioRestrictionsTable } from '../../components/tables/PortfolioRestrictionsTable';


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '10px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 10,
    height: 'calc(100% - 10px)',
    minHeight: '52vh',
}));

const mappedData = (details: SlaveAutoPilotPortfolio[]) => {
    const mapped: UserPilotOverviewData = details.map((detail: SlaveAutoPilotPortfolio) => ({
        userKey: detail.slaveUserKey,
        autoPilotSettingsKey: detail.autoPilotSettingsKey,
        pilot: detail.masterPortfolio.userFullName,
        allocation: detail.autoPilotSettings.allocationAmount,
        status: detail.autoPilotSettings.setupStatus,
        portfolio: {
            brokerName: detail.autoPilotSettings.slavePortfolio.brokerName,
            connectionStatus: detail.autoPilotSettings.slavePortfolio.connectionStatus,
            portfolioKey: detail.autoPilotSettings.slavePortfolio.portfolioKey,
        },
        portfolioConnectionState: detail.autoPilotSettings.slavePortfolio.connectionStatus,
        amountPending: detail.autoPilotSettings.amountPending,
        initiatedAt: detail.autoPilotSettings.initiatedAt,
        equilibriumState: '',
        isManualExecution: detail.isManualExecution
    }))[0];
    return mapped;
}

export const UserPilotDetailsPage: React.FC = () => {
    const {autoPilotSettingsKey} = useParams() || '';
    const whoisitResponse = useQueryCoordinate(whoisit, {variables: {autoPilotSettingsKey: parseInt(autoPilotSettingsKey as string)}});
    const userKey = whoisitResponse.data?.whoisit?.user?.userKey;
    const parsedSettingsKey = parseInt(autoPilotSettingsKey as string);
    const {data, loading, error} = useQueryAsUser(userPilotDetailsQuery, {
        userKey,
        autoPilotSettingsKey: parsedSettingsKey,
        variables: {userKey, autoPilotSettingsKey: parsedSettingsKey}
    });
    if (loading || !userKey) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    const details = data?.slaveAutoPilotPortfoliosGet?.slaveAutoPilotPortfolios ?? [];
    const mapped = mappedData(details);


    if (!mapped) return <div>No data</div>;
    return <Box>
        <Typography variant="h4">Pilot Details: {mapped.pilot}</Typography>
        <Box sx={{ display: 'flex' }}>
            <LegacyAdminButton path={`/slaves/${parsedSettingsKey}`}/>
            <BalancePortfolioButton autoPilotSettingsKey={parsedSettingsKey} />
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Item><UserPilotDetailsOverview data={mapped}/></Item>
            </Grid>
            <Grid item xs={8}>
                <Item>
                    <BalancingDecisionsTable
                        userKey={parseInt(userKey as string)}
                        autoPilotSettingsKey={parseInt(autoPilotSettingsKey as string)}
                        positions={details[0].currentPositions}
                        portfolioDetails={{portfolioKey: mapped.portfolio.portfolioKey, brokerName: mapped.portfolio.brokerName, connectionStatus: mapped.portfolio.connectionStatus}}
                    />
                </Item>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Item>
                    <TriggerHistoryTable info={{
                        userKey: parseInt(userKey as string),
                        autoPilotSettingsKey: parsedSettingsKey,
                        allocationAmount: mapped.allocation
                    }}/>
                </Item>
            </Grid>
            <Grid item xs={6}>
                <Item>
                    <FailedAutopilotOrdersTable autoPilotSettingsKey={parseInt(autoPilotSettingsKey as string)}/>
                </Item>
            </Grid>
            <Grid item xs={6} sx={{ height: '100%' }}>
                <Item>
                    <PortfolioRestrictionsTable userKey={parseInt(userKey as string)} portfolioKey={mapped.portfolio.portfolioKey} />
                </Item>
            </Grid>
        </Grid>
       
    </Box>;
};
