import React, { useState } from 'react';
import '../css/slave-portfolio-header-style.css';
import '../css/slave-portfolio-card-style.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from "@mui/material/Box";

const SlavePortfolioHeader = (
    {
        data,
        loading,
        error,
        currentOption,
        onChangeOption,
        dataSlave
    }
) => {

    const optionsList = [
        "all",
        "day",
        "week",
        "month",
        "ytd"
    ]

    const currentData = data ? data[currentOption] : null;
    const [showDropdown, setShowDropdown] = useState(false);


    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const handleOptionClick = (option) => {
        onChangeOption(option);
        setShowDropdown(false);
    };

    return (
        <div className="header">
            <div className='card-column-items alignItemsStart'>
                <h1>${(dataSlave?.totalValue) ? dataSlave?.totalValue : '0.0'}</h1>
                <p className="change"
                    style={{ color: (currentData.totalProfitLoss < 0) ? 'red' : '#4caf50' }}
                >
                    ${currentData ? currentData.totalProfitLoss.toFixed(2) : '0.00'} {currentData.totalProfitLoss < 0 ? '-' : '+'}({currentData ? `${(currentData.totalProfitLossPercent * 100).toFixed(1)}%` : '0.00%'})
                </p>
            </div>

            <div className="dropdown">
                <Box display="flex">
                    <button onClick={toggleDropdown} style={{fontSize: '20px', color: 'black'}}>{currentOption}</button>
                    <ArrowDropDownIcon />
                </Box>
                {showDropdown && (
                    <div className="dropdown-content">
                        {optionsList.map((option) =>
                            <p onClick={() => handleOptionClick(option)}>{option}</p>
                        )
                        }
                    </div>

                )}
            </div>
        </div>
    );
};

export default SlavePortfolioHeader;
