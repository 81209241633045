import {useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {
    InstitutionData,
    InstitutionPortfolio,
    listInstitutionsQuery
} from "../../clients/graphql/user/user-institutions.query";
import {ConnectedStatus} from "../../components/status/ConnectedStatus";
import {DisconnectedStatus} from "../../components/status/DisconnectedStatus";
import {Link} from "react-router-dom";
import * as _ from 'lodash';

export type UserInstitutionsListProps = {
    userKey: number,
}
export function UserInstitutionsList({ userKey }: UserInstitutionsListProps) {

    const {loading, error, data} = useQueryAsUser(listInstitutionsQuery, { userKey, variables: { userKey } });

    const unsortedInstitutionList: InstitutionData[] = data?.institutionsGet?.institutions ?? [];
    const institutionList = _.sortBy(unsortedInstitutionList, ['connectionStatus']);
    return (
        <>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ height: 200, overflowY: 'scroll', overflowX: 'scroll' }}>
                <List>
                    {
                        loading ? <CircularProgress /> :
                            error ? <Alert severity="warning">{error?.message}</Alert> :
                                institutionList.map((institution: InstitutionData, index: number) => <InstitutionDisplay key={index} userKey={userKey} institution={institution} />)
                    }
                </List>
            </Box>
        </>
    );
}

type InstitutionDisplayProps = {
    userKey: number,
    institution: InstitutionData,
}
function InstitutionDisplay({ userKey, institution }: InstitutionDisplayProps) {

    const [expanded, setExpanded] = useState<boolean>(false);

    return <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{institution.institutionName.split('_').map(_.capitalize).join(' ')}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
                {institution.connectionStatus === 'CONNECTED' ? <ConnectedStatus /> : <DisconnectedStatus /> }
            </Box>
        </AccordionSummary>
        <AccordionDetails>
            <InstitutionDetails userKey={userKey} institution={institution} />
        </AccordionDetails>
    </Accordion>
}

type InstitutionDetailsProps = {
    userKey: number,
    institution: InstitutionData,
}
function InstitutionDetails({ institution }: InstitutionDetailsProps) {

    const portfolios = (institution?.portfolios ?? [])?.map((portfolio: InstitutionPortfolio) => {
        return <ListItem component={Link} to={`/portfolios/${portfolio.portfolioKey}`} disablePadding>
            <ListItemButton>
                <ListItemText primary={portfolio.nickname} />
            </ListItemButton>
        </ListItem>
    });

    return <Box sx={{ textAlign: 'left' }}>
        <i></i>
        <Typography variant="h6">Portfolios:</Typography>
        <List>
            {portfolios}
        </List>
    </Box>
}


