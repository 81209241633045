import React, { useState } from 'react';
import { Box, Typography, IconButton, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import { Person2Outlined } from '@mui/icons-material';
import { useMutationCoordinate } from '../../clients/graphql/graphql.hooks';
import { jediReenableSlave } from '../../clients/graphql/user-pilots/autopilot-settings.mutation';
import ConfirmAlertDialog from '../../components/dialogs/confirm-alert';
import { BROKER_NAME, CONNECTION_STATUS } from '../../shared/portfolio-types';

export interface UserPilotOverviewData {
    userKey: number;
    autoPilotSettingsKey: number;
    pilot: string;
    allocation: number;
    status: string;
    portfolio: {
        brokerName: BROKER_NAME;
        connectionStatus: CONNECTION_STATUS;
        portfolioKey: number;
    };
    portfolioConnectionState: string;
    amountPending: number;
    initiatedAt: Date;
    equilibriumState: string;
    isManualExecution: boolean;
}

interface UserPilotOverviewProps {
    data: UserPilotOverviewData;
}


export const UserPilotDetailsOverview: React.FC<UserPilotOverviewProps> = ({ data }) => {
    const navigator = useNavigate();

    const [reenableMutation] = useMutationCoordinate(jediReenableSlave, { variables: { autoPilotSettingsKey: data.autoPilotSettingsKey } });

    const handleReenable = async () => {
        // const confirmed = confirm('Re-enabling the pilot will resume the autopilot. Are you sure you want to proceed?');
        // if(!confirmed) return;
        await reenableMutation();
        window.location.reload();
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" component="div">
                    {data.pilot}
                </Typography>
                <Divider sx={{ my: 1 }} />
            </Grid>

            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell><Person2Outlined style={{ marginRight: '10px' }} />Customer</TableCell>
                                <TableCell>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="outlined"
                                        tabIndex={-1}
                                        color='primary'
                                        endIcon={<ArrowForwardOutlinedIcon />}
                                        onClick={() => navigator(`/users/${data.userKey}`)}
                                    >
                                        #{data.userKey}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><ShowChartOutlinedIcon style={{ marginRight: '10px' }} />Portfolio</TableCell>
                                <TableCell>
                                    <Tooltip title={`portfolio is ${_.lowerCase(data.portfolioConnectionState)}`}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="outlined"
                                            tabIndex={-1}
                                            color={data.portfolioConnectionState === 'DISCONNECTED' ? 'error' : 'success'}
                                            startIcon={data.portfolioConnectionState === 'DISCONNECTED' && (
                                                <ErrorIcon style={{ color: 'red' }} />
                                            )}
                                            endIcon={<ArrowForwardOutlinedIcon />}
                                            onClick={() => navigator(`/portfolios/${data.portfolio.portfolioKey}`)}
                                        >
                                            {_.capitalize(data.portfolio.brokerName)}
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><MonitorHeartOutlinedIcon style={{ marginRight: '10px' }} />Status</TableCell>
                                <TableCell>
                                    {data.status === 'SHORT_CIRCUITED' ? (
                                        <React.Fragment>
                                            <ConfirmAlertDialog
                                                title='Are you sure you want to re-enable?'
                                                description='Re-enabling will turn on Autopilot for this customer'
                                                actionButtonTitle='Re-enable'
                                                onConfirm={handleReenable}
                                                buttonComponent={
                                                    <Button
                                                        component="label"
                                                        role={undefined}
                                                        variant="outlined"
                                                        tabIndex={-1}
                                                        color='error'
                                                        startIcon={<ErrorIcon style={{ color: 'red' }} />}
                                                        endIcon={<ArrowForwardOutlinedIcon />}
                                                    >
                                                        Re-enable
                                                    </Button>
                                                }
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <>
                                            {data.status === 'ACTIVE' && (
                                                <Tooltip title="Active">
                                                    <Button
                                                        disableRipple={true}
                                                        component="label"
                                                        role={undefined}
                                                        variant="outlined"
                                                        tabIndex={-1}
                                                        color='success'
                                                        startIcon={<CheckCircleOutlinedIcon style={{ color: 'success' }} />}
                                                    >Active</Button>
                                                </Tooltip>
                                            )}
                                            {data.status === 'DELETED' && (
                                                <Tooltip title="Deleted">
                                                    <Button
                                                        disableRipple={true}
                                                        component="label"
                                                        role={undefined}
                                                        variant="outlined"
                                                        tabIndex={-1}
                                                        color='error'
                                                        startIcon={<DeleteOutlinedIcon style={{ color: 'warning' }} />}
                                                    >Deleted</Button>
                                                </Tooltip>
                                            )}
                                            {!['SHORT_CIRCUITED', 'DELETED', 'ACTIVE'].includes(data.status) && (data.status.split('_').map(_.capitalize).join(' '))}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> <AttachMoneyOutlinedIcon style={{ marginRight: '10px' }} />Allocation</TableCell>
                                <TableCell>${data.allocation}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> <AutorenewOutlinedIcon style={{ marginRight: '10px' }} />Amount Pending</TableCell>
                                <TableCell>${data.amountPending}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><AccessTimeIcon style={{ marginRight: '10px' }} />Started At</TableCell>
                                <TableCell>{moment(data.initiatedAt).format('MM/DD/YYYY HH:MM')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><SettingsOutlinedIcon style={{ marginRight: '10px' }} />Execution Type</TableCell>
                                <TableCell> {data.isManualExecution ? 'MANUAL' : 'AUTOMATIC'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};
