import { Button, Tooltip } from "@mui/material";
import WifiIcon from '@mui/icons-material/Wifi';

export function ConnectedStatus() {
  return <Button
    component="label"
    variant="text"
    tabIndex={-1}
    color='success'
    startIcon={
      <Tooltip title="Institution Connected">
        <WifiIcon />
      </Tooltip>
    }
  >
    CONNECTED
  </Button>
}