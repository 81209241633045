import {PilotListTable} from "../../components/tables/PilotListTable";
import React from "react";
import {Box} from "@mui/material";
import {TriggerHistoryTable} from "../../components/tables/TriggerHistoryTable";

interface UserPilotAndTransactionBoxProps {
    info: {
        userKey: number;
        autoPilotSettingsKey: number;
        allocationAmount: number;
    }
}

export const UserPilotAndTransactionBox: React.FC<UserPilotAndTransactionBoxProps> = ({ info }) => {
    return (
        <Box sx={{width: '100%', height: '100%'}}>
            <PilotListTable userKey={info.userKey}/>
            <Box sx={{m: 1, p: 1}}></Box>
            <TriggerHistoryTable info={info} />
        </Box>
    )
}