import { Button } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LEGACY_ADMIN_URL } from "../../clients/ClientRegistry";

export type LegacyAdminButtonProps = {
  path: string
}

export function LegacyAdminButton({ path }: LegacyAdminButtonProps) {
  return <Button 
    component="a" 
    href={`${LEGACY_ADMIN_URL}${path}`}
    target="_blank" 
    rel="noopener noreferrer" 
    variant="outlined"
    color="primary"
    // startIcon={<OpenInNewIcon />}
  >
      View in Legacy
  </Button>;
}