import { gql } from '@apollo/client';

export const balancingDecisionsQuery = gql`
    query balancingDecisionsGet($autoPilotSettingsKey: Int!) {
        balancingDecisionsGet(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            balancingDecisions {
                symbol
                positionType
                orderSide
                reason
                kind
                quantity
                asset {
                    pictureUrl
                    currentPrice
                }
            }
            balancingParameters
            overAllocatedAmount
        }
    }
`;

export interface BalancingDecision {
    symbol: string;
    positionType: string;
    orderSide: string;
    reason: string;
    kind: string;
    quantity: number;
    asset: {
        pictureUrl: string;
        currentPrice: number;
    };
};

// {
//     "symbolBlacklist": [],
//     "positionTypeWhitelist": [
//         "EQUITY"
//     ],
//     "masterPositions": [
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 319,
//             "autoPilotSettingsKey": null,
//             "symbol": "NVDA",
//             "totalNetQuantity": 3.7,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 49.17400000000001,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 269.7892,
//             "unrealizedProfitLossPercent": 0.597231550495536,
//             "unrealizedReturn": 0.597231550495536,
//             "marketQuote": {
//                 "unitPrice": 122.09
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 78,
//             "autoPilotSettingsKey": null,
//             "symbol": "AAPL",
//             "totalNetQuantity": 1.8,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 155.3027112360612,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 115.39311977508981,
//             "unrealizedProfitLossPercent": 0.2921803416614502,
//             "unrealizedReturn": 0.2921803416614502,
//             "marketQuote": {
//                 "unitPrice": 219.41
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 144,
//             "autoPilotSettingsKey": null,
//             "symbol": "MSFT",
//             "totalNetQuantity": 0.7,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 292.0887126753388,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 116.90790112726285,
//             "unrealizedProfitLossPercent": 0.3637797589297783,
//             "unrealizedReturn": 0.3637797589297783,
//             "marketQuote": {
//                 "unitPrice": 459.1
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 296,
//             "autoPilotSettingsKey": null,
//             "symbol": "GOOGL",
//             "totalNetQuantity": 1.3,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 129.0172263736264,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 73.1026057142857,
//             "unrealizedProfitLossPercent": 0.3035507348252287,
//             "unrealizedReturn": 0.3035507348252287,
//             "marketQuote": {
//                 "unitPrice": 185.25
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 3,
//             "autoPilotSettingsKey": null,
//             "symbol": "CRWD",
//             "totalNetQuantity": 0.5,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 267.25,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 58.875,
//             "unrealizedProfitLossPercent": 0.30584415584415586,
//             "unrealizedReturn": 0.30584415584415586,
//             "marketQuote": {
//                 "unitPrice": 385
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 65973784,
//             "autoPilotSettingsKey": null,
//             "symbol": "PANW",
//             "totalNetQuantity": 0.51,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 300.92,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 20.440799999999992,
//             "unrealizedProfitLossPercent": 0.11753665689149556,
//             "unrealizedReturn": 0.11753665689149556,
//             "marketQuote": {
//                 "unitPrice": 341
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 15,
//             "autoPilotSettingsKey": null,
//             "symbol": "AMZN",
//             "totalNetQuantity": 0.39999999999999997,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 186.544,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 5.390399999999999,
//             "unrealizedProfitLossPercent": 0.06737326267373261,
//             "unrealizedReturn": 0.06737326267373261,
//             "marketQuote": {
//                 "unitPrice": 200.02
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 257,
//             "autoPilotSettingsKey": null,
//             "symbol": "TSLA",
//             "totalNetQuantity": 0.3,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 214.69,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 4.947000000000003,
//             "unrealizedProfitLossPercent": 0.07132969980102088,
//             "unrealizedReturn": 0.07132969980102088,
//             "marketQuote": {
//                 "unitPrice": 231.18
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 1,
//             "autoPilotSettingsKey": null,
//             "symbol": "AB",
//             "totalNetQuantity": 1.5,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 45.96,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -18.599999999999998,
//             "unrealizedProfitLossPercent": -0.369487485101311,
//             "unrealizedReturn": -0.369487485101311,
//             "marketQuote": {
//                 "unitPrice": 33.56
//             }
//         },
//         {
//             "portfolioKey": 8735,
//             "timeseriesKey": 23,
//             "autoPilotSettingsKey": null,
//             "symbol": "DIS",
//             "totalNetQuantity": 0.1,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 168.19631591119332,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -7.035631591119333,
//             "unrealizedProfitLossPercent": -0.7190956246033658,
//             "unrealizedReturn": -0.7190956246033658,
//             "marketQuote": {
//                 "unitPrice": 97.84
//             }
//         }
//     ],
//     "slavePositions": [
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296447,
//             "autoPilotSettingsKey": 916181,
//             "symbol": "AB",
//             "totalNetQuantity": 0.770893,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 33.779,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -0.16882556700000093,
//             "unrealizedProfitLossPercent": -0.006525625744934481,
//             "unrealizedReturn": -0.006525625744934481,
//             "marketQuote": {
//                 "unitPrice": 33.56
//             }
//         },
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296453,
//             "autoPilotSettingsKey": 916181,
//             "symbol": "TSLA",
//             "totalNetQuantity": 0.15387,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 187.7559,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 6.6816662670000015,
//             "unrealizedProfitLossPercent": 0.1878367505839606,
//             "unrealizedReturn": 0.1878367505839606,
//             "marketQuote": {
//                 "unitPrice": 231.18
//             }
//         },
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296470,
//             "autoPilotSettingsKey": 916181,
//             "symbol": "DIS",
//             "totalNetQuantity": 0.05185,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 102.6037,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -0.246997845,
//             "unrealizedProfitLossPercent": -0.0486886753883892,
//             "unrealizedReturn": -0.0486886753883892,
//             "marketQuote": {
//                 "unitPrice": 97.84
//             }
//         },
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296473,
//             "autoPilotSettingsKey": 916181,
//             "symbol": "NVDA",
//             "totalNetQuantity": 1.92957,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 123.2451,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -2.2288463069999813,
//             "unrealizedProfitLossPercent": -0.009461053321320257,
//             "unrealizedReturn": -0.009461053321320257,
//             "marketQuote": {
//                 "unitPrice": 122.09
//             }
//         }
//     ],
//     "brokerPositions": [
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296449,
//             "autoPilotSettingsKey": null,
//             "symbol": "AB",
//             "totalNetQuantity": 0.770893,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 33.779,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -0.16882556700000093,
//             "unrealizedProfitLossPercent": -0.006525625744934481,
//             "unrealizedReturn": -0.006525625744934481,
//             "marketQuote": {
//                 "unitPrice": 33.56
//             }
//         },
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296452,
//             "autoPilotSettingsKey": null,
//             "symbol": "TSLA",
//             "totalNetQuantity": 0.15387,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 187.7559,
//             "optionDetails": null,
//             "unrealizedProfitLoss": 6.6816662670000015,
//             "unrealizedProfitLossPercent": 0.1878367505839606,
//             "unrealizedReturn": 0.1878367505839606,
//             "marketQuote": {
//                 "unitPrice": 231.18
//             }
//         },
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296471,
//             "autoPilotSettingsKey": null,
//             "symbol": "DIS",
//             "totalNetQuantity": 0.05185,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 102.6037,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -0.246997845,
//             "unrealizedProfitLossPercent": -0.0486886753883892,
//             "unrealizedReturn": -0.0486886753883892,
//             "marketQuote": {
//                 "unitPrice": 97.84
//             }
//         },
//         {
//             "portfolioKey": 269642,
//             "timeseriesKey": 82296476,
//             "autoPilotSettingsKey": null,
//             "symbol": "NVDA",
//             "totalNetQuantity": 1.92957,
//             "positionType": "EQUITY",
//             "averageUnitCostBasis": 123.2451,
//             "optionDetails": null,
//             "unrealizedProfitLoss": -2.2288463069999813,
//             "unrealizedProfitLossPercent": -0.009461053321320257,
//             "unrealizedReturn": -0.009461053321320257,
//             "marketQuote": {
//                 "unitPrice": 122.09
//             }
//         }
//     ],
//     "settingParameters": {
//         "allocationAmount": 1000,
//         "amountPending": 0,
//         "slaveAutopilotPortfolioCash": 701.939955568,
//         "masterCashPositionAmount": 0,
//         "maxMasterPositionCount": 20
//     },
//     "brokerParameters": {
//         "fundsAvailable": 0,
//         "unsettledFunds": 0,
//         "assetBuyingPowerData": [
//             {
//                 "symbol": "AB",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "CRWD",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "AMZN",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "DIS",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "AAPL",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "MSFT",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "TSLA",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "GOOGL",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "NVDA",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             },
//             {
//                 "symbol": "PANW",
//                 "buyingPower": 0,
//                 "positionType": "EQUITY"
//             }
//         ],
//         "positionShareSizeData": [
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "AB",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "CRWD",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "AMZN",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "DIS",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "AAPL",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "MSFT",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "TSLA",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "GOOGL",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "NVDA",
//                 "minShareSize": 0.2
//             },
//             {
//                 "positionType": "EQUITY",
//                 "symbol": "PANW",
//                 "minShareSize": 0.2
//             }
//         ],
//         "ordersToPreventPDTInfraction": [],
//         "minFractionalBuyAmount": 1,
//         "mayRoundFractionalCorner": true,
//         "subDollarMinOrderRequirement": 0.2,
//         "institutionSellWholeFractionOnly": false,
//         "institutionAssetBlacklist": [
//             {
//                 "symbol": "LFG",
//                 "assetType": "STOCK"
//             },
//             {
//                 "symbol": "LGF",
//                 "assetType": "STOCK"
//             },
//             {
//                 "symbol": "SZK",
//                 "assetType": "STOCK"
//             }
//         ]
//     },
//     "masterValuation": 1984.7620000000004,
//     "slaveValuation": 1004.0369965479999,
//     "valuationRatioOfSlaveToMaster": 0.505872742700636,
//     "minBuyBalanceDecisionAmount": 20.08073993096,
//     "minSellBalanceDecisionAmount": 20.08073993096,
//     "inFlightAPOrders": []
// }

export interface BalancingParameters {
    brokerParameters: {
        fundsAvailable: number;
        unsettledFunds: number;
        assetBuyingPowerData: {
            symbol: string;
            buyingPower: number;
            positionType: string;
        }[];
        positionShareSizeData: {
            positionType: string;
            symbol: string;
            minShareSize: number;
        }[];
        ordersToPreventPDTInfraction: any[];
        minFractionalBuyAmount: number;
        mayRoundFractionalCorner: boolean;
        subDollarMinOrderRequirement: number;
        institutionSellWholeFractionOnly: boolean;
        institutionAssetBlacklist: {
            symbol: string;
            assetType: string;
        }[];
    };
    symbolBlacklist: string[];
    positionTypeWhitelist: string[];
    masterPositions: MasterPosition[];
    slavePositions: SlavePosition[];
    brokerPositions: BrokerPosition[];
    settingParameters: SettingParameters;
    masterValuation: number;
    slaveValuation: number;
    valuationRatioOfSlaveToMaster: number;
    minBuyBalanceDecisionAmount: number;
    minSellBalanceDecisionAmount: number;
    inFlightAPOrders: any[];
}

export interface MasterPosition {
    portfolioKey: number;
    timeseriesKey: number;
    autoPilotSettingsKey: number;
    symbol: string;
    totalNetQuantity: number;
    positionType: string;
    averageUnitCostBasis: number;
    optionDetails: any;
    unrealizedProfitLoss: number;
    unrealizedProfitLossPercent: number;
    unrealizedReturn: number;
    marketQuote: {
        unitPrice: number;
    }
}

export interface SlavePosition {
    portfolioKey: number;
    timeseriesKey: number;
    autoPilotSettingsKey: number;
    symbol: string;
    totalNetQuantity: number;
    positionType: string;
    averageUnitCostBasis: number;
    optionDetails: any;
    unrealizedProfitLoss: number;
    unrealizedProfitLossPercent: number;
    unrealizedReturn: number;
    marketQuote: {
        unitPrice: number;
    }
}

export interface BrokerPosition {
    portfolioKey: number;
    timeseriesKey: number;
    autoPilotSettingsKey: number;
    symbol: string;
    totalNetQuantity: number;
    positionType: string;
    averageUnitCostBasis: number;
    optionDetails: any;
    unrealizedProfitLoss: number;
    unrealizedProfitLossPercent: number;
    unrealizedReturn: number;
    marketQuote: {
        unitPrice: number;
    }
}

export interface SettingParameters {
    allocationAmount: number;
    amountPending: number;
    slaveAutopilotPortfolioCash: number;
    masterCashPositionAmount: number;
    maxMasterPositionCount: number;
}