import {Alert, Button, CircularProgress} from "@mui/material";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {gql} from "graphql-tag";

const slaveAutoPilotOrdersGet = gql`
    query($userKey: Int!){
        jediAutoPilotOrdersGet(input: {userKey: $userKey}){
            autoPilotOrders{
                title
                symbol
                orderSide
                averagePrice
                quantity
                transactionAmount
                filledDate
            }
        }
    }`;

export interface DownloadTradesButtonProps {
    userKey: string;
}

interface JediAutoPilotOrder {
    title: string
    symbol: string
    orderSide: string
    averagePrice: string
    quantity: string
    transactionAmount: string
    filledDate: string
}

export function DownloadTradesButton({userKey} : DownloadTradesButtonProps) {

    const { loading, error, data } = useQueryCoordinate(slaveAutoPilotOrdersGet, { variables: { userKey: parseInt(userKey) } });

    if (loading) {
        return <CircularProgress sx={{marginLeft: "40px"}}/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>;
    }

    const convertToCSV = (objArray : JediAutoPilotOrder[]) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = 'Title,Symbol,Order Side,Average Price,Quantity,Transaction Amount,Filled Date\r\n';

        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            let line = '';
            line += `${item.title || ''},`;
            line += `${item.symbol || ''},`;
            line += `${item.orderSide || ''},`;
            line += `${item.averagePrice || ''},`;
            line += `${item.quantity || ''},`;
            line += `${item.transactionAmount || ''},`;
            line += `${item.filledDate || ''}`;
            str += line + '\r\n';
        }
        return str;
    }

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(data?.jediAutoPilotOrdersGet?.autoPilotOrders)], { type: 'json' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${userKey}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            onClick={() => downloadCSV()}
            sx={{marginLeft: "10px"}}
        >
            Download Orders
        </Button>
    )
}